Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../node_modules/scandi-smpagebuilder/src/plugin/CategoryPage.component.plugin.js'),
require('./../node_modules/scandi-smpagebuilder/src/plugin/HomePage.container.plugin.js'),
require('./../node_modules/scandi-smpagebuilder/src/plugin/NoMatch.container.plugin.js'),
require('./../node_modules/scandi-smpagebuilder/src/plugin/ProductPage.component.plugin.js')]);
/* eslint linebreak-style: ["error", "windows"] */
/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import 'Util/Polyfill';

import 'Style/main';

import { render } from 'react-dom';

import './font.scss';

import './rtl.scss';

import App from 'Component/App';

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
